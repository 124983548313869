<script setup>
</script>
<template>
  <footer class=" text-light mt-4 p-4" >
    <div class="">
      <div class="row">
        <!-- Logo de la Empresa -->
        <div class="col-lg-12 mb-4 ">
          <h4 class="text-uppercase fw-bold mb-0" style="color: #6495ED;">EGAED</h4>
        </div>
      </div>
      <hr class="bg-light">
      <div class="row">
        <!-- Sección de Especializaciones Netzun -->
        <div class="col-md-3 mb-4">         
          <ul class="list-unstyled">
            <li><a href="#" class="text-light text-decoration-none">Excel</a></li>
            <li><a href="#" class="text-light text-decoration-none">Business Intelligence</a></li>
            <li><a href="#" class="text-light text-decoration-none">Marketing Digital</a></li>
            <li><a href="#" class="text-light text-decoration-none">Recursos Humanos</a></li>
            <li><a href="#" class="text-light text-decoration-none">Metodologías Ágiles</a></li>
          </ul>
        </div>
  
        <!-- Sección de Especializaciones en Alianza -->
        <div class="col-md-3 mb-4">         
          <ul class="list-unstyled">
            <li><a href="#" class="text-light text-decoration-none">Customer Experience Management</a></li>
            <li><a href="#" class="text-light text-decoration-none">Planificación de Medios Digitales</a></li>
            <li><a href="#" class="text-light text-decoration-none">Tecnologías Disruptivas e Innovación</a></li>
            <li><a href="#" class="text-light text-decoration-none">Diseño de Modas</a></li>
          </ul>
        </div>
  
      
      </div>
  
      <hr class="bg-light">
    <!-- Sección de Verificación de Certificados -->
  

 
      <div class="row ">
       
        <div class="col-md-3 mb-4">
          <h6 class="text-uppercase">Verifica el certificado</h6>
          <p>Valida el certificado EGAED ingresando el código único</p>
          <a href="#" class="btn btn-primary w-100">Verificar Certificado</a>
        </div>
           <!-- Sección de Más Información -->
    <div class="col-md-3 mb-4">
      <h6 class="text-uppercase">Más Información</h6>
      <ul class="list-unstyled">
        <li><a href="#" class="text-light text-decoration-none">Términos y Condiciones</a></li>
        <li><a href="#" class="text-light text-decoration-none">Políticas de Privacidad</a></li>
        <li><a href="#" class="text-light text-decoration-none">Preguntas Frecuentes</a></li>
        <li><a href="#" class="text-light text-decoration-none">Libro de Reclamos</a></li>
      </ul>
    </div>
        <div class="col-md-3 mb-4">
          <h6 class="text-uppercase">Contáctanos en</h6>
          <div class="">
            <a href="#" class="m-2 text-light fs-4"><i class="fab fa-facebook"></i> </a>
            <a href="#" class=" text-light fs-4"><i class="fab fa-instagram"></i></a>
            <a href="#" class=" m-2 text-light fs-4"><i class="fab fa-youtube"></i></a>
            <a href="#" class=" text-light fs-4"><i class="fab fa-linkedin"></i></a>
          </div>
          <p class="mb-0">
            <a href="mailto:contacto@egaed.com" class="text-light text-decoration-none">contacto@egaed.com</a><br>
            <a href="tel:+51922253397" class="text-light text-decoration-none">+51 993 745 120</a>
          </p>
        </div>

      </div>
  
      <!-- Derechos Reservados -->
      <div class="text-center mt-4">
        <p class="mb-0">&copy; 2024 EGAED. Todos los derechos reservados.</p>
      </div>
    </div>
  </footer>
  
  
</template>

<template>
  <div class="certificates-container">
    <h2 class="mb-4">Mis Certificados</h2>
    
    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr  >
            <th scope="col" class="text-primary">Nombres Curso <i class="bi bi-question-circle-fill ms-1"></i></th>
            <th scope="col" class="text-primary text-center">Calificación <i class="bi bi-question-circle-fill ms-1"></i></th>
             </tr>
        </thead>
        <tbody>
          <tr v-for="c in cursos_matriculados" :key="c.id">
            <td>
              <div class="d-flex align-items-center">
                <div class="certificate-icon me-3" :class="{ 'bg-primary': index === 1, 'bg-warning': index !== 1 }"></div>
                <p>{{c.curso.nombre}}</p>
              </div>
            </td>
            <td class="text-center" :class="{ 'text-white': index === 1 }">
              <button @click="irModulos(c.curso.id)" class="btn btn-primary">Certificados por Módulo</button>
            </td>
           
          </tr>
        </tbody>
      </table>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>

<script>
 import MatriculaService from "@/services/MatriculaService";
 import Preloader from "../components/Preloader.vue";
export default {
  name: 'CertificadosComponente',
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      cursos_matriculados: [],
      cargando:false,
      certificados: []
    }
  },
  mounted() {
  
  if (this.user) {
    this.getCursosMatriculadosPorUsuario(this.user.id);
  }
},
  components:{
      Preloader,
    },
    methods: {
      async getCursosMatriculadosPorUsuario(id) {
        try {
          this.cargando = true;
          const responseMatriculas =
            await MatriculaService.getCursosMatriculadosPorUsuario(id);
            this.cursos_matriculados=responseMatriculas.data.data
        
          this.cursosTomando = responseMatriculas.data.filter(
            (curso) => curso.estado === "en_progreso"
          );
        } catch (error) {
          console.error("Error al obtener estadísticas:", error);
        } finally {
          this.cargando = false;
        }
      },
     
     irModulos(id){
       this.$router.push({
         name:'CertificadoporModulo',
         params:{
           id_curso:id,
         }
       })
     },}
}
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.0/font/bootstrap-icons.css");

.certificates-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.table th {
  font-weight: normal;
  font-size: 0.9rem;
  padding-top: 0;
  padding-bottom: 15px;
}

.table td {
  padding-top: 12px;
  padding-bottom: 12px;
  vertical-align: middle;
}

.certificate-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.highlight-row {
  background-color: #3B348C !important;
  border-radius: 10px;
}

.btn-download {
  padding: 6px 10px;
  border-radius: 6px;
}

.btn-info {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-light {
  background-color: white;
  border-color: white;
}

.btn-light i {
  color: #3B348C;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-primary {
  background-color: #ff9800 !important;
}

.text-primary {
  color: #3B348C !important;
}

.bi-question-circle-fill {
  font-size: 0.8rem;
}

.bi-download {
  font-size: 1.2rem;
}
</style>
<template>
  <div class="container-fluid" >
    <main class="main">
      <div class="container-fluid">
        <div class="row mt-4">
          <div class="col-12">
            <h6 class="blanco">Calendario</h6>
          </div>
        </div>

        <div class="row mt-2">
          <div class="card azul blanco">
            <FullCalendar :options="calendarOptions">
              <template v-slot:eventContent="arg">
                <div class="p-2" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  <p class="blanco" style="font-size: 11px; font-weight: bold; margin: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ arg.timeText }}m<br />
                    {{ arg.event.title }}
                  </p>
                </div>
              </template>
            </FullCalendar>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import esLocale from "@fullcalendar/core/locales/es";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import SesionService from "../../services/SesionService";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: false,
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        weekends: true,
        events: [],
        locale: esLocale,
        eventClick: this.eventClick 
      },
    };
  },
  mounted() {
    this.cargarCalendario();
  },
  components: {
    FullCalendar,
  },
  methods: {
    eventClick(args) {
      args.jsEvent.preventDefault(); 
      window.open(args.event.url, "_blank"); 
    },
    cargarCalendario() {
      this.loading = true;

      SesionService.getObtenerSesionesPorUsuario(this.user.id)
        .then((response) => {
          const arrayEvents = response.data.data;
          let arr2 = this.calendarOptions.events;

          arrayEvents.forEach((e) => {
            arr2.push({
              title: e.nombre,
              date: e.fecha + " " + e.hora_inicio,
              url: e.enlace,
            });
          });

          this.loading = false;
        })
        .catch((error) => {
          console.error("Error al obtener sesiones:", error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>

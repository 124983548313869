<template>
    <div>
        <div id="customCarousel" class="carousel slide" data-bs-ride="carousel"
            style="background: linear-gradient(90deg, #070E27, #194994);">
            <!-- Indicadores (Puntos) -->
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#customCarousel" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#customCarousel" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#customCarousel" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
            </div>

            <div class="carousel-inner text-white">
                <!-- Slide 1 -->
                <div class="carousel-item active">
                    <div class="d-flex align-items-center" style="min-height: 400px;">
                        <div class="container d-flex">
                            <div class="w-50 d-flex flex-column justify-content-center">
                                <h2 class="mb-3 text-white">Educación Online de Calidad</h2>
                                <p class="mb-3">Accede a +650 cursos y más de 50 especialidades.</p>
                                <a href="#" class="btn btn-primary">Acceso Total Aquí</a>
                            </div>
                            <div class="w-50">
                                <img src="@/assets/images/2729.png" class="d-block w-100" alt="Image 1">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Slide 2 -->
                <div class="carousel-item">
                    <div class="d-flex align-items-center" style="min-height: 400px;">
                        <div class="container d-flex">
                            <div class="w-50 d-flex flex-column justify-content-center">
                                <h2 class="mb-3 text-white">Educación Online de Calidad</h2>
                                <p class="mb-3">Accede a +650 cursos y más de 50 especialidades.</p>
                                <a href="#" class="btn btn-primary">Acceso Total Aquí</a>
                            </div>
                            <div class="w-50">
                                <img src="@/assets/images/2729.png" class="d-block w-100" alt="Image 2">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Slide 3 -->
                <div class="carousel-item">
                    <div class="d-flex align-items-center" style="min-height: 400px;">
                        <div class="container d-flex">
                            <div class="w-50 d-flex flex-column justify-content-center">
                                <h2 class="mb-3 text-white">Educación Online de Calidad</h2>
                                <p class="mb-3">Accede a +650 cursos y más de 50 especialidades.</p>
                                <a href="#" class="btn btn-primary">Acceso Total Aquí</a>
                            </div>
                            <div class="w-50">
                                <img src="@/assets/images/2729.png" class="d-block w-100" alt="Image 3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <h3 class="text-white text-center">Conoce nuestros principales Cursos</h3>
            <div class="row mt-2">
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
            </div>
            <div class="row text-center mt-4">
                <div class="d-flex justify-content-center">
                    <button class="btn btn-primary d-inline-block" style="width: auto; ">Ver más especialidades</button>
                </div>
            </div>
        </div>
        <div class="mt-4 text-center text-white">
            <h3 class="text-white">Estudiantes de +10 Paises
                <img src="https://netzunplus.s3.amazonaws.com/frontend-assets/flags/per.svg" class="m-2" width="30px"
                    alt="">
                <img src="https://netzunplus.s3.amazonaws.com/frontend-assets/flags/chl.svg" width="30px" alt="">
                <img src="https://netzunplus.s3.amazonaws.com/frontend-assets/flags/ecu.svg" class="m-2" width="30px"
                    alt="">
                <img src="https://netzunplus.s3.amazonaws.com/frontend-assets/flags/arg.svg" width="30px" alt="">
                <img src="https://netzunplus.s3.amazonaws.com/frontend-assets/flags/pry.svg" class="m-2" width="30px"
                    alt="">
            </h3>
            <div class="container my-5">
                <div class="row text-center text-light">
                    <!-- Primer elemento -->
                    <div class="col-md-6 mb-4 d-flex flex-column align-items-center">
                        <i class="bi bi-person-fill fs-1 mb-2" style="color: #A3A2D8;"></i>
                        <h3 class="fw-bold">+1,300,000</h3>
                        <p>estudiantes registrados</p>
                    </div>

                    <!-- Segundo elemento -->
                    <div class="col-md-6 mb-4 d-flex flex-column align-items-center">
                        <i class="bi bi-star-fill fs-1 mb-2" style="color: #A3A2D8;"></i>
                        <h3 class="fw-bold">4.7/5</h3>
                        <p>+50 mil calificaciones</p>
                    </div>
                </div>

                <hr class="bg-light">

                <div class="row text-center text-light">
                    <!-- Tercer elemento -->
                    <div class="col-md-6 mb-4 d-flex flex-column align-items-center">
                        <i class="bi bi-people-fill fs-1 mb-2" style="color: #A3A2D8;"></i>
                        <h3 class="fw-bold">45%</h3>
                        <p>de nuestros estudiantes, usa Egaed para lanzar/trabajar en su startup</p>
                    </div>

                    <!-- Cuarto elemento -->
                    <div class="col-md-6 mb-4 d-flex flex-column align-items-center">
                        <i class="bi bi-chat-left-dots-fill fs-1 mb-2" style="color: #A3A2D8;"></i>
                        <h3 class="fw-bold">98%</h3>
                        <p>de nuestros estudiantes declaran que Egaed los volvió más competitivos</p>
                    </div>
                </div>
            </div>

        </div>
        <div>
            <div class="container my-5 py-5">
                <div class="row align-items-center text-white">
                    <!-- Texto de la parte superior -->
                    <div class="col-12 mb-5 text-center">
                        <h4 class="fw-bold  text-white" style="color: #A3A2D8;">Educación online para tu<br>crecimiento
                            profesional</h4>
                    </div>

                    <!-- Imagen y texto descriptivo -->
                    <div class="col-md-6 d-flex justify-content-center">
                        <img src="@/assets/images/2150808343.jpg" alt="Imagen de Clases Cortas"
                            class=" p-4 img-fluid rounded shadow">
                    </div>

                    <div class="col-md-6">
                        <h4 class="fw-bold mb-3  -text-white">Clases cortas (micro-learning)</h4>
                        <p class="mb-3">Aprende con cursos concisos de 2 horas de duración en promedio, subdivididos en
                            videos de max 10 minutos.</p>
                        <ul class="list-unstyled">
                            <li class="mb-2">
                                <i class="bi bi-check-circle-fill" style="color: #A3A2D8;"></i> Información concisa
                                sobre skills específicos
                            </li>
                            <li class="mb-2">
                                <i class="bi bi-check-circle-fill" style="color: #A3A2D8;"></i> Más fácil de aprender, y
                                super prácticos
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row align-items-center text-white">
                    <div class="col-md-6">
                        <h4 class="fw-bold mb-3  text-white">
                            Acceso para siempre</h4>
                        <p class="mb-3">Si compras un curso o especialización, tendrás acceso a los contenidos grabados
                            para siempre.</p>
                        <ul class="list-unstyled">
                            <li class="mb-2">
                                <i class="bi bi-check-circle-fill" style="color: #A3A2D8;"></i> Accede a tus clases
                                cuando quieras, sin horarios
                            </li>
                            <li class="mb-2">
                                <i class="bi bi-check-circle-fill" style="color: #A3A2D8;"></i>Accede a tus clases las
                                veces que quieras
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center p-4">
                        <img src="@/assets/images/2148890420.jpg" alt="Imagen de Clases Cortas"
                            class="p-4 img-fluid rounded shadow">
                    </div>
                </div>
                <div class="row align-items-center text-white">
                   
                    <!-- Imagen y texto descriptivo -->
                    <div class="col-md-6 d-flex justify-content-center">
                        <img src="@/assets/images/148476.jpg" alt="Imagen de Clases Cortas"
                            class=" p-4 img-fluid rounded shadow">
                    </div>

                    <div class="col-md-6">
                        <h4 class="fw-bold mb-3  text-white">Certifícate al aprobar
                            tus cursos</h4>
                        <p class="mb-3">Al finalizar un curso tendrás que aprobar un examen y te emitiremos un certificado digital que valide tus habilidades.</p>
                        <ul class="list-unstyled">
                            <li class="mb-2">
                                <i class="bi bi-check-circle-fill" style="color: #A3A2D8;"></i> Certificado con código único
                            </li>
                            <li class="mb-2">
                                <i class="bi bi-check-circle-fill" style="color: #A3A2D8;"></i> Mejora tu CV
                            </li>
                            <li class="mb-2">
                                <i class="bi bi-check-circle-fill" style="color: #A3A2D8;"></i> Impulsa tu carrera profesional
                            </li>
                        </ul>
                    </div>
                </div>
            

            </div>

        </div>
        <div class="container my-5">
            <h2 class="text-center fw-bold mb-5" style="color: #A3A2D8;">¿Por qué aprender con EGAED?</h2>
            <div class="row text-center text-light">
              <!-- Tarjeta 1 -->
              <div class="col-md-3 mb-4 d-flex flex-column align-items-center">
                <i class="bi bi-person-fill fs-1 mb-3" style="color: #A3A2D8;"></i>
                <h4 class="fw-bold">Expertos de LATAM</h4>
                <p>Tu equipo aprenderá de profesionales reconocidos de Latinoamérica y España.</p>
              </div>
          
              <!-- Tarjeta 2 -->
              <div class="col-md-3 mb-4 d-flex flex-column align-items-center">
                <i class="bi bi-clock-fill fs-1 mb-3" style="color: #A3A2D8;"></i>
                <h4 class="fw-bold">Avanza a tu ritmo</h4>
                <p>Todo nuestro contenido es grabado y está online, por lo que tu equipo podrá verlo en cualquier dispositivo y en el momento que prefieran.</p>
              </div>
          
              <!-- Tarjeta 3 -->
              <div class="col-md-3 mb-4 d-flex flex-column align-items-center">
                <i class="bi bi-calendar-check-fill fs-1 mb-3" style="color: #A3A2D8;"></i>
                <h4 class="fw-bold">Constante Actualización</h4>
                <p>Alineados a las tendencias del mercado laboral, nuestros cursos están en constante actualización para que tu equipo esté siempre a la vanguardia.</p>
              </div>
          
              <!-- Tarjeta 4 -->
              <div class="col-md-3 mb-4 d-flex flex-column align-items-center">
                <i class="bi bi-award-fill fs-1 mb-3" style="color: #A3A2D8;"></i>
                <h4 class="fw-bold">Máxima calidad</h4>
                <p>Los videos son de máxima calidad producidos por el equipo audiovisual de  EGAED.</p>
              </div>
            </div>
          </div>
          
          <div class="container my-5">
            <h2 class="text-center fw-bold" style="color: #A3A2D8;">¿Qué opinan nuestros usuarios?</h2>
            <p class="text-center mb-5" style="color: #A3A2D8;">Tenemos una calificación promedio de 4.7 de 5 estrellas</p>
          
            <div class="row">
              <!-- Tarjeta 1 -->
              <div class="col-md-3 mb-4">
                <div class="card h-100 text-light" style="background-color: #1A1F36;">
                  <div class="card-body">
                    <h5 class="card-title" style="color: #A3A2D8;">Transformación cultural con prácticas de Management 3.0</h5>
                    <p class="card-subtitle mb-2">Carlos Castañeda</p>
                    <p class="mb-1" style="color: #FFD700;">★★★★★ 5</p>
                    <p class="card-text">De todos los cursos dictados en esta especialización, es el mejor, muy didáctico y full ejemplos. Espero que de todas las herramientas mencionadas haya de más...</p>
                    <a href="#" class="text-primary">Ver más</a>
                  </div>
                </div>
              </div>
          
              <!-- Tarjeta 2 -->
              <div class="col-md-3 mb-4">
                <div class="card h-100 text-light" style="background-color: #1A1F36;">
                  <div class="card-body">
                    <h5 class="card-title" style="color: #A3A2D8;">Power BI Básico</h5>
                    <p class="card-subtitle mb-2">Luis Zapata</p>
                    <p class="mb-1" style="color: #FFD700;">★★★★★ 5</p>
                    <p class="card-text">Muy recomendado</p>
                    <a href="#" class="text-primary">Ver más</a>
                  </div>
                </div>
              </div>
          
              <!-- Tarjeta 3 -->
              <div class="col-md-3 mb-4">
                <div class="card h-100 text-light" style="background-color: #1A1F36;">
                  <div class="card-body">
                    <h5 class="card-title" style="color: #A3A2D8;">Introducción a las criptomonedas</h5>
                    <p class="card-subtitle mb-2">Alejandro Linares</p>
                    <p class="mb-1" style="color: #FFD700;">★★★★★ 5</p>
                    <p class="card-text">Te enseña todo lo básico para que puedas seguir investigando sobre las criptomonedas y ponerlo en práctica.</p>
                    <a href="#" class="text-primary">Ver más</a>
                  </div>
                </div>
              </div>
          
              <!-- Tarjeta 4 -->
              <div class="col-md-3 mb-4">
                <div class="card h-100 text-light" style="background-color: #1A1F36;">
                  <div class="card-body">
                    <h5 class="card-title" style="color: #A3A2D8;">Personalidad creativa</h5>
                    <p class="card-subtitle mb-2">Abel Condezo</p>
                    <p class="mb-1" style="color: #FFD700;">★★★★★ 5</p>
                    <p class="card-text">No</p>
                    <a href="#" class="text-primary">Ver más</a>
                  </div>
                </div>
              </div>
            </div>
          
            <!-- Paginación -->
            <nav aria-label="Page navigation" class="mt-4">
              <ul class="pagination justify-content-center">
               
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                
              </ul>
            </nav>
          </div>
          

    </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SidenavList from "./SidenavList.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const layout = computed(() => store.state.layout);
const sidebarType = computed(() => store.state.sidebarType);
</script>
<template>
  <div
    v-show="layout === 'default'"
    class="min-height-300 position-absolute w-100 bg-gris"
  />

  <aside
    class="overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs"
    :class="`${isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start '}    
      ${layout === 'landing' ? 'bg-gris shadow-none' : ' '} ${sidebarType}`"
    id="sidenav-main"
  >
    <div class="sidenav-header text-center pb-4" style="background-color: #f4f3fb;">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>

      <router-link class="m-0 text-center "  to="/">
        <img
            src="@/assets/images/marcas/logo-escucla-global.png"
            alt="Icono de laptop"
            width="80%"
            class="mt-2"
          />
      </router-link>
  
    </div>
   
      <sidenav-list  />
   
  </aside>
</template>
<script>

export default {
  mame: "Logo Dashboard",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      marca: "",
    };
  },
  created() {
    if (this.user && this.user.id_cliente) {
      const idcliente = this.user.id_cliente;
      if (idcliente == 1) {
        this.marca = "esformacion";
      } else if (idcliente == 2) {
        this.marca = "masprofesional";
      } else if (idcliente == 3) {
        this.marca = "capacitasional";
      }
    }
  },
};
</script>

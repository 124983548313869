import { createRouter, createWebHistory } from "vue-router";
import MisCursos from "../views/Dahsboard/MisCursos.vue";
import Curso from "../views/Dahsboard/Curso.vue";
import Leccion from "../views/Dahsboard/Leccion.vue";
import Certificados from "../views/Dahsboard/Certificados.vue";
import PerfilUsuario from "../views/Dahsboard/PerfilUsuario.vue";



//Marcas
import HomeMarcas from "../views/Pages/HomeMarcas.vue";
import Login from "../views/Pages/Login.vue";
import Inicio from "../views/Dahsboard/Inicio.vue";
import Calendario from "../views/Dahsboard/Calendario.vue";
import Modulos from "../views/Dahsboard/Modulo.vue";
import Soporte from "../views/Dahsboard/Soporte.vue";
import Examen from "../views/Dahsboard/Examen.vue";
import CertificadosPorModulo from "../views/Dahsboard/CertificadosPorModulo.vue";
import Recuperar from "../views/Pages/Recuperar.vue";
import Principal from "../views/Pages/Inicio.vue";

const routes = [{
    path: "/",
    name: "Principal",
    component: Principal,
    meta: {
        title: 'Centro Global',
        auth: false,
    },
    },

    {
        path: "/plataformas",
        name: "Home Marcas",
        component: HomeMarcas,
        meta: {
            title: 'Plataformas - Aula Virtual',
            auth: false,
        },
        redirect: "/login/:marca?",
    },

    {
        path: "/inicio",
        name: "Inicio",
        component: Inicio,
        meta: {
            title: 'Inicio - Mi Aula Virtual',
            auth: true,
        },
    },

    {
        path: "/login/:marca?",
        name: "Login",
        component: Login,
        meta: {
            title: 'Inicio de Sesión',
            auth: false,
        },
    },
    {
        path: "/restablecer/:idusuario",
        name: "Recuperar",
        component: Recuperar,
        meta: {
            title: 'Recuperar Contraseña',
            auth: false,
        },
    },


    {
        path: "/miscursos",
        name: "Mis Cursos",
        component: MisCursos,
        meta: {
            title: 'Mis Cursos',
            auth: true,

        },
    },
    {
        path: "/miscursos/:id_curso/modulos",
        name: "Modulos",
        component: Modulos,
        meta: {
            title: 'Examen',
            auth: true,

        },
    },
    {
        path: "/examen/modulo/:idmodulo",
        name: "Examen",
        component: Examen,
        meta: {
            title: 'Examen',
            auth: true,

        },
    },
    {
        path: "/calendario",
        name: "Calendario",
        component: Calendario,
        meta: {
            title: 'Calendario',
            auth: true,

        },
    },


    {
        path: "/miscursos/:idcurso",
        name: "Curso",
        component: Curso,
        meta: {
            title: 'Examen',
            auth: true,

        },
    },




    {
        path: '/perfil',
        name: 'PerfilUsuario',
        component: PerfilUsuario,
        meta: {
            title: 'Examen',
            auth: true,

        },
    },


    {
        path: "/miscursos/:idcurso/modulos/:idmodulo/leccion/:idleccion",
        name: "Leccion",
        component: Leccion,
        meta: {
            title: 'Examen',
            auth: true,

        },


    },

    {
        path: "/miscertificados",
        name: "Mis Certificados",
        component: Certificados,
        meta: {
            title: 'Examen',
            auth: true,

        },
    },

    {
        path: '/certificado-modulo/:id_curso',
        name: 'CertificadoporModulo',
        component: CertificadosPorModulo,
        meta: {
            title: 'Examen',
            auth: true,

        },
    },


    {
        path: "/soporte",
        name: "Soporte",
        component: Soporte,
        meta: {
            title: 'Examen',
            auth: true,

        },
    },   
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('authToken');
    const user = JSON.parse(localStorage.getItem('user'));

    if (to.matched.some(record => record.meta.auth)) {
        if (!isAuthenticated) {
            next({ name: 'Home Marcas' });

        } else if (!user) {
            next({ name: 'Home Marcas' });
        } else {
            next();
        }
    } else {
        next();
    }
});


export default router;
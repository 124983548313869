<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center w-100"
      style="height: 80vh; width: 100%"
      v-if="!haComenzado"
    >
      <div>
        <h1 class="text-danger fs-6">Examen</h1>
        <p>Curso: {{ nombre_curso }}</p>
        <h6>Módulo : {{ nombre_modulo }}</h6>
        <div class="card p-4 w-100">
          <h5>Instrucciones</h5>
          <ul>
            <li>Tienes {{ cantindad_intentos }} intentos para resolver el examen</li>
            <li>El examen consta de {{ cantidad_preguntas }} preguntas</li>
            <li>Tienes {{ examen.duracion }} minutos para resolver el examen</li>
            <div class="btn-group mt-4" role="group" aria-label="botones examen">
              <button
                type="button"
                class="btn btn-danger"
                @click="comenzarExamen"
                v-show="cantindad_intentos > 0"
              >
                Empezar
              </button>
              <button type="button" class="btn btn-light" @click="$router.go(-1)">
                Cancelar
              </button>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-center align-items-center w-100"
      style="height: 80vh; width: 100%"
      v-if="haComenzado && preguntaActual"
    >
      <div>
        <div style="font-size: 10px;"> Minutos restantes : {{ cronometroExamen }}</div>
        <div class="card p-4">
          <p>{{ preguntaActual.pregunta }}</p>

          <div v-if="preguntaActual.tipo_pregunta === 1">
            <li
              v-for="opcion in preguntaActual.opciones"
              :key="opcion.id"
              style="list-style: none"
            >
              <input
                type="radio"
                :name="'pregunta_' + preguntaActual.id"
                :value="opcion"
                @change="
                  guardarRespuestas(
                    preguntaActual.tipo_pregunta,
                    preguntaActual.id,
                    preguntaActual.valor,
                    opcion.opcion,
                    opcion.condicion
                  )
                "
              />
              {{ opcion.opcion }}
            </li>
          </div>
          <div v-else-if="preguntaActual.tipo_pregunta == 2">
            <label for="respuestaAbierta">Escriba su respuesta:</label>
            <input
              type="text"
              id="respuestaAbierta"
              class="form-control"
              v-model="inputRespuesta"
              @input="
                guardarRespuestas(
                  preguntaActual.tipo_pregunta,
                  preguntaActual.id,
                  preguntaActual.valor,
                  inputRespuesta,
                  0
                )
              "
              placeholder="Escriba su respuesta aquí"
            />
          </div>

          <div class="mt-4 g-2">
            <button
              class="m-2 btn btn-secondary"
              v-if="preguntaIndice > 0"
              @click="anteriorPregunta"
            >
              Anterior
            </button>
            <button
              class="m-2 btn btn-danger"
              v-if="preguntaIndice < preguntas.length - 1"
              @click="siguientePregunta"
            >
              Siguiente
            </button>
            <button
              class="m-2 btn btn-success"
              v-if="preguntaIndice == preguntas.length - 1"
              @click="enviarExamen"
            >
              Enviar Examen
            </button>
          </div>
        </div>
      </div>
    </div>

    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import CursoService from "../../services/CursoService";
import ExamenService from "../../services/ExamenService";
import Preloader from "../components/Preloader.vue";
import DetalleEvaluacionService from "../../services/DetalleEvaluacionService";
import CertificadoService from "../../services/CertificadoService";

export default {
  data() {
    return {
      haComenzado: false,
      preguntaIndice: 0,
      cargando: false,
      idmodulo: this.$route.params.idmodulo,
      user: JSON.parse(localStorage.getItem("user")),
      nombre_curso: "",
      nombre_modulo: "",
      inputRespuesta: "",
      cantidad_preguntas: 0,
      examen: {
        intentos: 0,
        duracion: 0,
      },
      preguntas: [],
      respuestas: [],
      respuestasAbiertas: {},
      todasLasRespuestas: [],
      notaFinal: 0,
      modulo: null,
      detalleEvaluacionData: null,
      horasModulo: 0,
      porcentajeAprobacion: 60,
      detalle_evaluacion: {},

      cantindad_intentos: 0,
      total_valor_examen: 0,
      opcionmultiple: true,

      tiempoRestante: 0, // Tiempo restante en segundos
      intervalo: null,
    };
  },
  created() {
    this.fetchExamen();
  },
  computed: {
    preguntaActual() {
      return this.preguntas[this.preguntaIndice];
    },
    cronometroExamen() {
      const minutos = Math.floor(this.tiempoRestante / 60);
      const segundos = this.tiempoRestante % 60;
      return `${minutos}:${segundos < 10 ? '0' + segundos : segundos}`;
    },
  },
  components: {
    Preloader,
  },
  methods: {
    iniciarCronometro() {
      this.tiempoRestante = this.examen.duracion * 60;
      this.intervalo = setInterval(() => {
        if (this.tiempoRestante > 0) {
          this.tiempoRestante--;
        } else {
          clearInterval(this.intervalo);
          this.$swal({
              icon: "success",
              title: "Tiempo Terminado",
              text: 'Presione OK para enviar Examen',
            }).then(() => {
              this.enviarExamen();
            });
          
          
        }
      }, 1000);
    },
    detenerCronometro() {
      clearInterval(this.intervalo);
    },

    async fetchExamen() {
      await this.fecthNombreCurso();
      try {
        this.cargando = true;
        const response = await ExamenService.getExamenPorModulo(this.idmodulo);
        this.examen = response.data.data;
        this.cantindad_intentos = this.examen.intentos;
        await this.fecthPreguntas(this.examen.id);
      } catch (error) {
        console.error("Error fetching Examen:", error);
      } finally {
        this.cargando = false;
      }
    },

    async fecthNombreCurso() {
      try {
        this.cargando = true;
        const response = await CursoService.getNombreCurso(this.idmodulo);
        this.nombre_curso = response.data.nombre_curso;
        this.nombre_modulo = response.data.nombre_modulo;
      } catch (error) {
        console.error("Error fetching curso:", error);
      } finally {
        this.cargando = false;
      }
    },

    async fecthPreguntas(id) {
      try {
        this.cargando = true;
        const response = await ExamenService.getPreguntasPorEvaluacion(id);
        this.preguntas = response.data.data;
        this.cantidad_preguntas = this.preguntas.length;
        this.fetchDetalledeEvaluaciones(id, this.user.id);

        // this.validarTipoDePreguntas();
      } catch (error) {
        console.error("Error fetching preguntas:", error);
      } finally {
        this.cargando = false;
      }
    },

    async fetchDetalledeEvaluaciones(examen, usuario) {
      try {
        this.cargando = true;
        const response = await DetalleEvaluacionService.detalleEvaluacion(
          examen,
          usuario
        );
        const detalleEvaluacion = response.data.data;
        console.log(detalleEvaluacion);
        if (detalleEvaluacion != 0) {
          this.detalle_evaluacion = detalleEvaluacion;
          this.cantindad_intentos =
            this.cantindad_intentos - this.detalle_evaluacion.intentos;
        } else {
          console.log("estoy a punto de crear");

          this.crearDetalleExamen(1);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.cargando = false;
      }
    },
    validarTipoDePreguntas() {
      this.preguntas = this.preguntas.map((pregunta) => {
        return {
          ...pregunta,
          tipo: pregunta.tipo !== undefined ? pregunta.tipo : 1,
        };
      });
    },

    async comenzarExamen() {
      try {
        this.cargando = true;

        if (this.detalle_evaluacion.intentos) {
          await this.actualizarDetalleEvaluacion(
            this.detalle_evaluacion.id,
            this.detalle_evaluacion.intentos + 1
          );
          this.haComenzado = true;
          this.iniciarCronometro();
        } else {
          await this.crearDetalleExamen(1);
          this.haComenzado = true;
        }
      } catch (error) {
        console.error("Error al iniciar el examen:", error);
      } finally {
        this.cargando = false;
      }
    },
    async actualizarDetalleEvaluacion(id, intentos) {
      try {
        const data = {
          intentos: intentos,
          fecha_evaluacion: new Date().toISOString().slice(0, 10),
          estado: 1,
        };
        await DetalleEvaluacionService.actualizarDetalleEvaluacionIntentos(id, data);
      } catch (error) {
        console.error("Error al actualizar el detalle de evaluación:", error);
      } finally {
        this.cargando = false;
      }
    },
    async actualizarDetalleEvaluacionNota(nota) {
      try {
        const data = {
          id_evaluacion: this.detalle_evaluacion.id_evaluacion,
          id_alumno: this.detalle_evaluacion.id_alumno,
          nota: nota,
          fecha_evaluacion: new Date().toISOString().slice(0, 10),
          estado: 1,
        };
        await DetalleEvaluacionService.actualizarDetalleEvaluacion(
          this.detalle_evaluacion.id,
          data
        );
        this.generarCertificado(nota, this.idmodulo);
      } catch (error) {
        console.error("Error al actualizar el detalle de evaluación:", error);
      } finally {
        this.cargando = false;
      }
    },

    async crearDetalleExamen(intentos = 1) {
      try {
        const data = {
          id_evaluacion: this.examen.id,
          id_alumno: this.user.id,
          fecha_evaluacion: new Date().toISOString().slice(0, 10),
          nota: 0,
          condicion: 0,
          intentos: intentos,
          estado: 1,
        };
        const response = await DetalleEvaluacionService.crearDetalleEvaluacion(data);
        this.detalle_evaluacion = response.data.data;
      } catch (error) {
        console.error("Error al crear detalle de evaluación:", error);
      } finally {
        this.cargando = false;
      }
    },

    async generarCertificado(notaFinal, id_modulo) {
      try {
        const id_usuario = this.user.id;
        const response = await CertificadoService.getCertificadoporModulodeUsuario(
          id_modulo,
          id_usuario
        );
        const certificados = response.data.data;

        if (!certificados || certificados.length === 0) {
          const data = {
            id_modulo: id_modulo,
            id_usuario: id_usuario,
            fecha_emision: new Date().toISOString().slice(0, 10),
            nota: notaFinal,
            fecha_inicio: new Date().toISOString().slice(0, 10),
            fecha_fin: new Date().toISOString().slice(0, 10),
            horas: this.horasModulo,
            codigo_certificado: `CERT-${Math.random()
              .toString(36)
              .substr(2, 9)
              .toUpperCase()}`,
            certificado_lado_a: "-",
            certificado_lado_b: "-",
            estado: 1,
          };

          await CertificadoService.crearCertificado(data);
          this.$swal({
            icon: "success",
            title: "Certificado Creado",
            text: "Tu certificado ha sido generado exitosamente.",
          }).then(() => {
            this.$router.push("/");
          });
        } else {
          const certificadoActual = certificados[0];
          if (notaFinal > certificadoActual.nota) {
            const data = {
              nota: notaFinal,
              fecha_emision: new Date().toISOString().slice(0, 10),
            };
            await CertificadoService.actualizarCertificado(certificadoActual.id, data);
            this.$swal({
              icon: "success",
              title: "Certificado Actualizado",
              text: `Tu certificado ha sido actualizado con una nueva nota de: ${notaFinal}`,
            }).then(() => {
              this.$router.push("/");
            });
          } else {
            this.$swal({
              icon: "info",
              title: "Examen Realizado",
              text:
                "Felicidades por realizar el examen. No se actualizará el certificado porque la nota obtenida es igual o inferior a la registrada previamente.",
            }).then(() => {
              this.$router.push("/");
            });
          }
        }
      } catch (error) {
        console.error("Error al generar o actualizar certificado:", error);
      }
    },
    siguientePregunta() {
      if (this.preguntaIndice < this.preguntas.length - 1) {
        this.preguntaIndice++;
      }
    },

    anteriorPregunta() {
      if (this.preguntaIndice > 0) {
        this.preguntaIndice--;
      }
    },

    guardarRespuestas(tipo, idpregunta, valor, respuesta, es_verdadero) {
      if (tipo == 2) {
        this.opcionmultiple = false;
      }

      if (es_verdadero == 1) {
        this.total_valor_examen = parseInt(this.total_valor_examen) + parseInt(valor);
      }
      let respuestasObjeto = {
        id_pregunta: idpregunta,
        id_detalle_evaluacion: this.detalle_evaluacion.id,
        respuesta: respuesta,
        es_verdadero: es_verdadero,
        estado: 1,
      };
      const index = this.respuestas.findIndex((res) => res.id_pregunta === idpregunta);
      if (index !== -1) {
        this.respuestas[index] = respuestasObjeto;
      } else {
        this.respuestas.push(respuestasObjeto);
      }

      console.log(this.respuestas);
    },
    async enviarExamen() {
      this.detenerCronometro();
      console.log("opcionmultiple", this.opcionmultiple);
      console.log("nota", this.total_valor_examen);

      try {
        this.cargando = true;
        for (const respuesta of this.respuestas) {
          const response = await ExamenService.guardarRespuestas(respuesta);
          console.log(response); // Log de cada respuesta enviada
        }
        if (this.opcionmultiple) {
          console.log("entre actualziar");
          this.actualizarDetalleEvaluacionNota(this.total_valor_examen);
        } else {
          this.$swal({
            icon: "success",
            title: "Examen Enviado",
            text: "",
          }).then(() => {
            this.$router.push("/");
          });
        }
      } catch (error) {
        console.error("Error fetching Examen:", error);
      } finally {
        this.cargando = false;
      }
    },
  },
};
</script>

<style></style>
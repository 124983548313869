  <template>
      <div class="contact-container">
        <h2 class="contact-title blanco">Contáctanos</h2>
        
        <div class="contact-list">
          <div class="contact-item contact-email">
            <div class="icon-container">
              <i class="bi bi-envelope-fill"></i>
            </div>
            <div class="contact-info">
              <p class="contact-type">Correo electrónico</p>
              <p class="contact-detail">info@egaed.com</p>
            </div>
          </div>
    
          <div class="contact-item">
            <div class="icon-container facebook">
              <i class="bi bi-facebook"></i>
            </div>
            <div class="contact-info">
              <p class="contact-type">Facebook</p>
              <p class="contact-detail">https://egaed.com/</p>
            </div>
          </div>
    
          <div class="contact-item">
            <div class="icon-container instagram">
              <i class="bi bi-instagram"></i>
            </div>
            <div class="contact-info">
              <p class="contact-type">Instagram</p>
              <p class="contact-detail">https://egaed.com/</p>
            </div>
          </div>
    
          <div class="contact-item">
            <div class="icon-container whatsapp">
              <i class="bi bi-whatsapp"></i>
            </div>
            <div class="contact-info">
              <p class="contact-type">WhatsApp</p>
              <p class="contact-detail">+34 654 321 654</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    
    <script>
    export default {
      name: 'ComponenteContacto'
    }
    </script>
    
    <style scoped>
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.0/font/bootstrap-icons.css");

  .contact-container {
    max-width: 400px;
    padding: 20px;
  }

  .contact-title {
   
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .contact-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .contact-item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  /* Animación de escala */
  .contact-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }

  /* Fondo blanco para todos los elementos, incluido el correo electrónico */
  .contact-email {
    background-color: white;
    color: #3B348C;
  }

  /* Ajustes de color para el icono y el texto */
  .contact-email .icon-container {
    color: #3B348C;
  }

  .contact-email .contact-type {
    color: #3B348C;
  }

  .contact-email .contact-detail {
    color: #666;
  }

  .icon-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-right: 15px;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
  }

  .contact-type {
    font-weight: bold;
    margin: 0;
  }

  .contact-detail {
    margin: 0;
    font-size: 0.9rem;
    color: #666;
  }

  .facebook { color: #1877F2; }
  .instagram { color: #E4405F; }
  .whatsapp { color: #25D366; }
</style>

<template>
  <div class="container-fluid" >
    <div class="row">
      <div class="col-md-12">
        <!-- Slider Banner de Ofertas -->
        <div
          class="text-white rounded-3 position-relative overflow-hidden"
          style="cursor: pointer; min-height: 220px"
          @click="goToLink(banners[currentBanner]?.link)"
        >
          <transition name="fade" mode="out-in">
            <div
              v-if="banners[currentBanner]?.image"
              :key="currentBanner"
              class="banner-image"
              :style="{
                backgroundImage: `url(${getBannerImageUrl(
                  banners[currentBanner].image
                )})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }"
            ></div>
            <div
              v-else
              class="banner-placeholder d-flex justify-content-center align-items-center h-100"
            >
              <span>No hay banners disponibles</span>
            </div>
          </transition>
        </div>

        <!-- Botones asociados al banner 
        <div
          class="d-flex justify-content-start mt-3 buttons-outside gap-3"
          v-if="banners[currentBanner] && banners[currentBanner].buttons?.length"
        >
          <button
            v-for="(button, btnIndex) in banners[currentBanner].buttons"
            :key="btnIndex"
            @click.stop="goToLink(button.link)"
            class="btn btn-light btn-sm m-1"
          >
            {{ button.text }}
          </button>
        </div>-->
      </div>
    </div>
    <!--Vista de Principal del Estudiante -->
    <div class="row mt-4 text-center">
      <div class="col-md-2">
        <div class="card p-4 azul"  style="border: 1px solid white;">
          <div class="d-flex justify-content-center align-items-center">
            <img
              class="white"
              src="@/assets/images/icons/aprender-en-linea.png"
              alt="Icono de laptop"
              width="15%"
            />
          </div>
          <p class="mt-2">Cursos Matriculados</p>
          <span class="p-2 text-white rounded-3" style="background-color: #0e2967">
            {{ cantidad_cursos }}
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card p-4 azul" style="border: 1px solid white;">
          <div class="d-flex justify-content-center align-items-center">
            <img
              src="@/assets/images/icons/ganador.png"
              alt="Icono de certificado"
              width="15%"
            />
          </div>
          <p class="mt-2">Certificados Obtenidos</p>
          <span class="p-2 text-white rounded-3" style="background-color: #0e2967">
            {{ certificados.length }}
          </span>
        </div>
      </div>
    </div>

    <div>
      <br><br>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <h5 class="blanco">Cursos que estás Tomando</h5>
      </div>
    </div>

    <div class="row mt-2 g-4">
      <div class="col-md-2" v-for="c in cursos_matriculados" :key="c.id">
        <div class="card" @click="irModulos(c.curso.id)" style="cursor: pointer">
          <img :src="getImagenUrl(c.curso.imagen)" class="card-img-top"  alt="Curso" />
          <div class="card-body azul" >
            <h6 class="card-title blanco">
              {{ c.curso.nombre }}<br />
              <span class="blanco2" style="font-size: 11px"> {{ c.curso.horas }} Horas</span>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import MatriculaService from "@/services/MatriculaService";
import CertificadoService from "@/services/CertificadoService";
import Preloader from "../components/Preloader.vue";
import UsuarioService from "@/services/UsuarioService";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      cantidad_cursos: "",
      certificados: [],
      cursos_matriculados: [],
      id_cliente: "",
      cargando: false,

      banners: [],
      currentBanner: 0,
      intervalId: null,
    };
  },
  components: {
    Preloader,
  },
  mounted() {
    if (this.user) {
      this.obtenerEstadisticas(this.user.id);
      this.obtenerCertificados(this.user.id);
      this.id_cliente = this.user.id_cliente;
      this.fetchBanners(this.id_cliente);
      /*this.startAutoSlide();*/
    }
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
  methods: {
    irModulos(id) {
      this.$router.push({
        name: "Modulos",
        params: {
          id_curso: id,
        },
      });
    },
    getImagenUrl(imagen) {
      if (imagen) {
        return process.env.VUE_APP_API_URL + "/storage/" + imagen;
      } else {
        return require("@/assets/images/fondos/curso-defecto.png");
      }
    },
    async obtenerEstadisticas(id) {
      try {
        this.cargando = true;
        const responseMatriculas = await MatriculaService.getCursosMatriculadosPorUsuario(
          id
        );
        this.cantidad_cursos = responseMatriculas.data.data.length;
        this.cursos_matriculados = responseMatriculas.data.data;
      } catch (error) {
        console.error("Error al obtener estadísticas:", error);
      } finally {
        this.cargando = false;
      }
    },
    async obtenerCertificados(id) {
      try {
        const responseCertificados = await CertificadoService.getcertificadoPorUsuario(
          id
        );
        this.certificados = responseCertificados.data.data.filter(
          (certificado) => certificado.estado === 1
        );
      } catch (error) {
        console.error("Error al obtener certificados:", error);
      }
    },
    async fetchBanners(id_cliente) {
      try {
        const response = await UsuarioService.getbanercliente(id_cliente);
        const data = response.data;
        const banners = [];

        // Procesar los banners desde la API
        if (data.banner_uno && data.link_banner_uno) {
          banners.push({
            image: data.banner_uno,
            link: data.link_banner_uno,
            buttons: [
              { text: data.boton_1, link: data.link_boton_1 },
              { text: data.boton_2, link: data.link_boton_2 },
              { text: data.boton_3, link: data.link_boton_3 },
            ],
          });
        }

        if (data.banner_dos && data.link_banner_dos) {
          banners.push({
            image: data.banner_dos,
            link: data.link_banner_dos,
            buttons: [
              { text: data.boton_1, link: data.link_boton_1 },
              { text: data.boton_2, link: data.link_boton_2 },
              { text: data.boton_3, link: data.link_boton_3 },
            ],
          });
        }

        this.banners = banners;

        if (this.banners.length > 0) {
          this.startAutoSlide();
        }
      } catch (error) {
        console.error("Error al obtener los banners:", error);
      }
    },

    getBannerImageUrl(imagen) {
      if (imagen) {
        if (imagen.startsWith("http://") || imagen.startsWith("https://")) {
          return imagen;
        } else {
          return process.env.VUE_APP_API_URL + "/storage/" + imagen;
        }
      } else {
        return "";
      }
    },
    startAutoSlide() {
      if (this.banners.length > 0) {
        this.intervalId = setInterval(() => {
          this.currentBanner = (this.currentBanner + 1) % this.banners.length;
        }, 5000);
      }
    },
    stopAutoSlide() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    nextBanner() {
      this.currentBanner = (this.currentBanner + 1) % this.banners.length;
    },
    goToLink(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
  },
};
</script>

<style>
.slide-enter-active {
  transition: transform 0.5s ease-in-out;
  position: absolute;
  width: 100%;
}

.slide-leave-active {
  transition: transform 0.5s ease-in-out;
  position: absolute;
  width: 100%;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(-100%);
}

.banner-image {
  width: 100%;
  height: 220px;
  border-radius: 10px;
}

.banner-text {
  width: 100%;
  height: 220px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 20px;
}

.banner-placeholder {
  width: 100%;
  height: 220px;
  background-color: #ccc;
  color: #333;
  text-align: center;
}

.buttons-outside {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 20px;
}

.navigate-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.navigate-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>

<template>
  <div class="container-fluid">
    <h2 class="dtb-title"></h2>
    <div class="mt-4">
      <h4 class="blanco">Módulos</h4>
      <div class="table-responsive azul mt-4 mb-4">
        <table class="table">
          <thead>
            <tr class="blanco">
              <th class="blanco2">Nombre</th>
              <th class="d-none d-sm-table-cell text-center">Lecciones</th>
              <th class="d-none d-sm-table-cell text-center">Calificaciones</th>
              <th class="d-none d-sm-table-cell text-center">Certificado</th>
            </tr>
          </thead>
          <tbody  >
            <tr
              v-for="modulo in modulos"
              :key="modulo.id"
             
              class="p-4 azul blanco2"
            >
              <td class="d-flex justify-content-between align-items-center py-2">
                <span class="me-3">{{ modulo.nombre }}</span>
                <button
                  class="btn btn-danger btn-sm"
                  @click="irLeccion(modulo.id, modulo.primera_leccion_id)"
                >
                  Ingresar
                </button>
              </td>
              <td class="d-none d-sm-table-cell text-center py-2">{{ modulo.cantidad_lecciones }}</td>
              <td class="d-none d-sm-table-cell text-center py-2">{{ obtenerCalificacion(modulo.id) }}</td>
              <td class="d-none d-sm-table-cell text-center py-2">{{ obtenerCertificacion(modulo.id) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import CalificacionService from "../../services/CalificacionService";
import CertificadoService from "../../services/CertificadoService";
import ModuloService from "../../services/ModuloService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "Modulo",
  data() {
    return {
      modulos: [],
      id_curso: this.$route.params.id_curso,
      calificaciones: {},
      certificados: {},
      cursoNombre: "",
      id_cliente: "",
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  components: {
    Preloader,
  },
  created() {
    this.fetchModulos(this.id_curso);
  },

  methods: {
    async llamarCalificacion(id_modulo) {
      try {
        const response =
          await CalificacionService.getCalificacionporModulodeUsuario(
            id_modulo,
            this.user.id
          );
        this.calificaciones[id_modulo] = response.data.data[0].calificacion;
      } catch (error) {
        console.error("Error fetching calificación", error);
      }
    },

    async llamarCertificado(id_modulo) {
      try {
        const response =
          await CertificadoService.getCertificadoporModulodeUsuario(
            id_modulo,
            this.user.id
          );
        const certificado = response.data.data[0];

        this.certificados[id_modulo] = certificado
          ? { nota: certificado.nota }
          : null;

        console.log(
          "Certificado para módulo",
          id_modulo,
          ":",
          this.certificados[id_modulo]
        );
      } catch (error) {
        console.error("Error al obtener el certificado:", error);
      }
    },
    obtenerCalificacion(id_modulo) {
      if (this.certificados[id_modulo] && this.certificados[id_modulo].nota) {
        console.log("Nota del certificado:", this.certificados[id_modulo].nota);
        return this.certificados[id_modulo].nota;
      }
      console.log("Calificación general:", this.calificaciones[id_modulo]);
      return this.calificaciones[id_modulo] || "Sin calificación";
    },
    obtenerCertificacion(id_modulo) {
      return this.certificados[id_modulo] ? "Obtenido" : "Sin certificado";
    },
    async fetchModulos(id_curso) {
      try {
        this.cargando = true;
        const response = await ModuloService.getModulosporCurso(id_curso);
        this.modulos = response.data.data;

        for (const modulo of this.modulos) {
          this.llamarCalificacion(modulo.id);
          this.llamarCertificado(modulo.id);
        }
      } catch (error) {
        console.error("Error fetching Modulos", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los módulos.",
        });
      } finally {
        this.cargando = false;
      }
    },

    irLeccion(id_m, id_l) {
    if (id_l) {
      this.$router.push({
        name: "Leccion",
        params: {
          idcurso: this.id_curso,
          idmodulo: id_m,
          idleccion: id_l,
        },
      });
    } else {
      this.$swal({
        icon: "warning",
        title: "Lección no disponible",
        text: "Este módulo no tiene lecciones disponibles para ingresar.",
      });
    }
  },
},
};
</script>

<style scoped></style>